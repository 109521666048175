import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { apiUrl } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService {

  response:any;
  apiLink = apiUrl+'/api/';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  async getAllBenefs(filter = {}) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'beneficiary/get_all', {filter: filter}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.beneficiaries;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  async getBenefDetails(benefId) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'beneficiary/get_details', {id: benefId}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.beneficiary;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }

  async getAllBenefData(benefDetails:any, filter = {}) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'beneficiary/get_all_data', {form_id:benefDetails.id, filter:filter}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return {
        formdata: this.response.formdatas,
        totalCount: this.response.total_count
      };
    });
  }

  async addBenef(benefDetails:any) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'beneficiary/add', benefDetails, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return true;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  async updateBenef(benefDetails:any, forMobile = false) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    let url = this.apiLink+'beneficiary/update';
    if(forMobile) {
      url = this.apiLink+'beneficiary/update_mobile';
    }

    return this.http.post(url, benefDetails, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return true;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  async updateLocation(benefDetails:any, location:number) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    let updateDetails = {
      benef_id: benefDetails.id,
      location: location
    }
    return this.http.post(this.apiLink+'beneficiary/update_location', updateDetails, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  async submitBenef(benefData, benefDetails, locDetails = {}) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    let data = {
      locDetails: locDetails,
      form_id: benefDetails.id,
      user_id: userDetails.user.id,
      form_data: JSON.stringify(benefData)
    }
    return this.http.post(this.apiLink+'beneficiary/collect', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return true;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  async updateBenefData(benefData, benefDetails, data_id) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    let data = {
      data_id: data_id,
      form_data: JSON.stringify(benefData)
    }
    return this.http.post(this.apiLink+'beneficiary/update_data', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return true;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  async deleteBenefData(data_id) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    let data = {
      data_id: data_id
    }
    return this.http.post(this.apiLink+'beneficiary/delete_data', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return true;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }


  //============= Add Beneficiary Group ===================
  async addBeneficiaryGroup(beneficiaryGroupDetails: any) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    beneficiaryGroupDetails.name = beneficiaryGroupDetails.name.trim();
    return this.http.post(this.apiLink + 'beneficiary/addgroup', beneficiaryGroupDetails, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return true;
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  //===========get parent Geneficiary Group=================
  async getAllParentBeneficiaryGroup(org_code, type:any = null, location:any = {}) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'beneficiary/get_parent_beneficiary_group', {org_code: org_code, type: type, location: location}, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.beneficiarygroup;
    });
  }

  //===========get all SMG Group tagged to GSMS Group=================
  async getSmgInGsms(org_code, gsms_id) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'beneficiary/get_smg_in_gsms', {org_code: org_code, gsms_id: gsms_id}, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.beneficiarygroup;
    });
  }

  //===========update Geneficiary Group=================
  async updateBeneficiaryGroup(beneficiarygroupDetails: any) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'beneficiary/update_Beneficiary_Group', beneficiarygroupDetails, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return true;
      }
    });
  }

  //===========delete BeneficiaryGroup=================
  async deleteBeneficiaryGroup(beneficiarygroupDetails: any) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'beneficiary/delete_Beneficiary_Group', beneficiarygroupDetails, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return true;
      }
    });
  }

  async tagGroup(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'beneficiary/tag_group', data, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
    });
  }
  async untagGroup(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'beneficiary/untag_group', data, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
    });
  }
  async getAllGroupTagging(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'beneficiary/get_group_tagging', data, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return this.response.taggings;
    });
  }
  async getAllGroupBeneficiaries(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'beneficiary/get_group_beneficiaries', data, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return this.response.taggings;
    });
  }

  //===============map location beneficiary group===================//
  async mapLocation(mapDetails:any) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'beneficiary/map_group_location', mapDetails, {headers: headers}).toPromise().then(response => {
     // console.log(response)
      this.response = response;
      if(this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return true;
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }
  //===============map location in gsms group===================//
  async mapGsmsLocation(mapDetails:any) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'beneficiary/map_gsms_location', mapDetails, {headers: headers}).toPromise().then(response => {
     // console.log(response)
      this.response = response;
      if(this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return true;
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }
  //===============map location msms group===================//
  async mapMsmsLocation(mapDetails:any) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'beneficiary/map_msms_location', mapDetails, {headers: headers}).toPromise().then(response => {
     // console.log(response)
      this.response = response;
      if(this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return true;
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }

  //===========enable Benif=================
  async enableBenef(benifId) {
    let userDetails = await this.authService.getUserDetails(),
      token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'beneficiary/enable', {benifId : benifId}, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return true;
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }

  //===========disable Benif=================
  async disableBenef(benifId) {
    let userDetails = await this.authService.getUserDetails(),
      token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'beneficiary/disable', {benifId : benifId}, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return true;
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }

  //===========import beneficiary=================
  async importBeneficiaryData(beneficiary, jsonData) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'beneficiary/import', {beneficiaryId : beneficiary.id, jsonData: jsonData}, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return true;
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }
}
