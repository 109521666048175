import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';
import { apiUrl } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  response:any;
  apiLink = apiUrl+'/api/lookup/';

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
  ) { }

  // Handle Server Call
  async handleGetRequest(url) {
    return this.http.get(url).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return this.response.data;
    }).catch(err => {
      console.error(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }
  
  // Global Locations
  async getGlobalLocations(dependent = 0, loc_type: string | null = null, loc_parent_id: string | null = null) {
    // Construct URL
    let url = this.apiLink+`get_global_location?dependent=${dependent}&loc_type=${loc_type}`;
    if(loc_parent_id) {
      url = url + `&loc_parent_id=${loc_parent_id}`;
    }
    
    return this.handleGetRequest(url);
  }

  // Org Locations
  async getOrgLocations(dependent = 0, org_code: string, loc_type: string | null = null, loc_parent_id: string | null = null) {
    // Construct URL
    let url = this.apiLink+`get_location?dependent=${dependent}&org_code=${org_code}&loc_type=${loc_type}`;
    if(loc_parent_id) {
      url = url + `&loc_parent_id=${loc_parent_id}`;
    }
    
    return this.handleGetRequest(url);
  }
  
  // Project Locations
  async getProjLocations(dependent = 0, org_code: string, project_id: string, loc_type: string | null = null, loc_parent_id: string | null = null) {
    // Construct URL
    let url = this.apiLink+`get_proj_location?dependent=${dependent}&org_code=${org_code}&project_id=${project_id}&loc_type=${loc_type}`;
    if(loc_parent_id) {
      url = url + `&loc_parent_id=${loc_parent_id}`;
    }
    
    return this.handleGetRequest(url);
  }

  // User Locations
  async getUserLocations(dependent = 0, org_code: string, project_id: string, user_id: string, user_role: string, loc_type: string | null = null, loc_parent_id: string | null = null) {
    // Construct URL
    let url = this.apiLink+`get_user_location?dependent=${dependent}&org_code=${org_code}&user_id=${user_id}&role_id=${user_role}&loc_type=${loc_type}`;
    if(project_id) {
      url = url + `&project_id=${project_id}`;
    }
    if(loc_parent_id) {
      url = url + `&loc_parent_id=${loc_parent_id}`;
    }
    
    return this.handleGetRequest(url);
  }

  // Beneficiary Group Locations
  async getBenefGroupLocations(dependent = 0, org_code: string, group_id: string, loc_type: string | null = null, loc_parent_id: string | null = null) {
    // Construct URL
    let url = this.apiLink+`get_benef_group_location?dependent=${dependent}&org_code=${org_code}&group_id=${group_id}&loc_type=${loc_type}`;
    if(loc_parent_id) {
      url = url + `&loc_parent_id=${loc_parent_id}`;
    }
    
    return this.handleGetRequest(url);
  }

  // Organization FPOs
  async getOrgFPOs(org_code: string) {
    // Construct URL
    let url = this.apiLink+`get_fpo?org_code=${org_code}`;
    
    return this.handleGetRequest(url);
  }

  // Organization Clients
  async getClients(org_code: string) {
    // Construct URL
    let url = this.apiLink+`get_crm_client?org_code=${org_code}`;
    
    return this.handleGetRequest(url);
  }

  // Organization Programs
  async getPrograms(org_code: string, project_id:any = null) {
    // Construct URL
    let url = this.apiLink+`get_program?org_code=${org_code}`;
    if(project_id) {
      url = url + `&project_id=${project_id}`;
    }
    
    return this.handleGetRequest(url);
  }

  // Organization Programs tagged to Projects
  async getProgramsWithProjects(org_code: string, project_id:any = null) {
    // Construct URL
    let url = this.apiLink+`get_program_with_project?org_code=${org_code}`;
    if(project_id) {
      url = url + `&project_id=${project_id}`;
    }
    
    return this.handleGetRequest(url);
  }

  // Organization Projects
  async getProjects(org_code: string, project_id:any = null, user_id:any = null) {
    // Construct URL
    let url = this.apiLink+`get_project?org_code=${org_code}`;
    if(project_id) {
      url = url + `&project_id=${project_id}`;
    }
    if(user_id) {
      url = url + `&user_id=${user_id}`;
    }
    
    return this.handleGetRequest(url);
  }

  async getMilestones(org_code: string, project_id:any = null) {
    // Construct URL
    let url = this.apiLink+`get_milestone?org_code=${org_code}`;
    if(project_id) {
      url = url + `&project_id=${project_id}`;
    }
    
    return this.handleGetRequest(url);
  }
  async getSubMilestones(org_code: string, project_id:any = null, milestone_id:any = null) {
    // Construct URL
    let url = this.apiLink+`get_tasklist?org_code=${org_code}`;
    if(project_id) {
      url = url + `&project_id=${project_id}`;
    }
    if(milestone_id) {
      url = url + `&milestone_id=${milestone_id}`;
    }

    return this.handleGetRequest(url);
  }

  // Organization Assigned Tasks
  async getAssignedTasks(user_id:any) {
    // Construct URL
    let url = this.apiLink+`get_assigned_task?user_id=${user_id}`;
    
    return this.handleGetRequest(url);
  }

  async getActivities(org_code: string, project_id:any = null) {
    // Construct URL
    let url = this.apiLink+`get_activities?org_code=${org_code}`;
    if(project_id) {
      url = url + `&project_id=${project_id}`;
    }

    return this.handleGetRequest(url);
  }

  async getUsers(org_code: string, project_id:any = null) {
    // Construct URL
    let url = this.apiLink+`get_users?org_code=${org_code}`;
    if(project_id) {
      url = url + `&project_id=${project_id}`;
    }

    return this.handleGetRequest(url);
  }

  // SDGs
  async getSDGs(org_code: any = null) {
    // Construct URL
    let url = this.apiLink+`get_sdg`;
    if(org_code && org_code.length > 0) {
      url = url + `?org_code=${org_code}`;
    }
    
    return this.handleGetRequest(url);
  }

  // Sectors
  async getSectors(org_code: any = null) {
    // Construct URL
    let url = this.apiLink+`get_sector`;
    if(org_code && org_code.length > 0) {
      url = url + `?org_code=${org_code}`;
    }
    
    return this.handleGetRequest(url);
  }

  // Currencies
  async getCurrencies() {
    // Construct URL
    let url = this.apiLink+`get_currency`;
    
    return this.handleGetRequest(url);
  }

  // IRIS
  async getIRIS() {
    // Construct URL
    let url = this.apiLink+`get_iris`;
    
    return this.handleGetRequest(url);
  }
}
