import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';

import { FcmService } from '../services/fcm.service';
import { apiUrl } from '../../environments/environment';
import { Subject } from 'rxjs';

const AUTH_TOKEN = "AUTH_TOKEN",
AUTH_STATUS = 'AUTH_STATUS',
USER_TYPE = "USER_TYPE",
USER_DATA = "USER_DATA";

interface loginData {
  email: string,
  password: string
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  allmenu:any = [];
  response:any;
  apiLink = apiUrl+'/api/auth/';

  public urlChangeObservable = new Subject();

  constructor(
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    private fcmService: FcmService,
  ) { }

  //set, remove and get stored user details
  async setUserDetails(response:any) {
    localStorage.setItem(AUTH_STATUS, '1');
    localStorage.setItem(USER_TYPE, response.type);
    localStorage.setItem(AUTH_TOKEN, response.token);
    localStorage.setItem(USER_DATA, JSON.stringify(response.user[0]));
    
    return true;
  }
  async removeUserDetails() {
    // Remove fcm token from localStorage
    let fcmToken = localStorage.getItem('fcmToken');
    if(fcmToken) {
      // Unregister FCM Token From Verdentum Server
      await this.unRegisterFcmToken(fcmToken);
      
      // Unregister FCM Token From Firebase Server
      this.fcmService.unregisterToken();
      
      // Remove FCM Token From Localhost
      localStorage.removeItem('fcmToken');
    }
    
    localStorage.removeItem(USER_DATA);
    localStorage.removeItem(USER_TYPE);
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_STATUS);

    // Remove Menu Active localStorage
    localStorage.removeItem('currentHead');
    localStorage.removeItem('currentSides');
    localStorage.removeItem('selectedSide');
    
    return true;
  }
  async getUserDetails() {
    if(this.isAuthenticated()) {
      const type = localStorage.getItem(USER_TYPE);
      const token = localStorage.getItem(AUTH_TOKEN);
      const user:any = localStorage.getItem(USER_DATA);
      
      return {
        user: JSON.parse(user),
        token: token,
        type: type
      };
    }
    else {
      return {};
    }
  }

  //status check
  isAuthenticated() {
    let authState:any = localStorage.getItem(AUTH_STATUS);
    if(!authState) return false;
    
    authState = parseInt(authState);
    return authState == 1;
  }
  //tracking user login state
  trackAuthStatus() {
    if(!this.isAuthenticated()) {
      this.router.navigate(['/']);
    } else {
      this.getUserDetails().then(userDetails => {
        if(userDetails.type == 'vadmin') {
          this.router.navigate(['vadmin']);
        } else if(userDetails.type == 'user') {
          this.allmenu = userDetails.user['menu'] ?  userDetails.user['menu'] : [];
          
          // Check if REDIRECT_URL is set
          const REDIRECT_URL = localStorage.getItem('REDIRECT_URL') || null;
          const REDIRECT_URL_MOD = REDIRECT_URL ? REDIRECT_URL.replace("/user/", "") : null;
          
          let redirect = '';
          if(REDIRECT_URL && REDIRECT_URL.length > 0) {
            let header_menu:any = this.getHeaderMenu(REDIRECT_URL_MOD, true);
            
            if(!header_menu.children || header_menu.children.length === 0) {
              header_menu['children'] = [];
            }
            header_menu = this.getChildMenu(header_menu, parseInt(header_menu['menu_level']));

            // Remove REDIRECT_URL from localStorage
            localStorage.removeItem('REDIRECT_URL');

            // Set current header menu in localStorage
            localStorage.setItem('currentHead', JSON.stringify(header_menu));
            // Set current side menu in localStorage
            localStorage.setItem('currentSides', JSON.stringify(header_menu.children));
            localStorage.setItem('selectedSide', 'user/' + REDIRECT_URL_MOD);

            // Redirect to default page
            redirect = 'user/' + REDIRECT_URL_MOD;
          } else if(userDetails.user['default_menu_link'] != 'profile') {
            let default_header_menu = userDetails.user['default_header_menu'];

            if(!default_header_menu.children || default_header_menu.children.length === 0) {
              default_header_menu['children'] = [];
            }
            default_header_menu = this.getChildMenu(default_header_menu, parseInt(default_header_menu['menu_level']));
            
            // Set current header menu in localStorage
            localStorage.setItem('currentHead', JSON.stringify(default_header_menu));
            // Set current side menu in localStorage
            localStorage.setItem('currentSides', JSON.stringify(default_header_menu.children));
            localStorage.setItem('selectedSide', 'user/' + userDetails.user['default_menu_link']);

            // Redirect to default page
            redirect = 'user/' + userDetails.user['default_menu_link'];
          } else {
            // Redirect to default page
            redirect = 'user/profile';
          }
          
          // Reload page to refresh the menu
          this.router.navigate([redirect]).then(() => {
            // window.location.reload();
          });
        }
      });
    }
  }
  // Get all child menus
  getChildMenu(parentMenu:any, menu_level:number) {
    for (const mKey in this.allmenu) {
      let menuItem = this.allmenu[mKey];
      
      if(menuItem['menu_level'] == (menu_level+1)
      && menuItem['parent_menu'] == parentMenu['menu_id']) {
        let parent = {
          menu_level: menuItem['menu_level'],
          menu_id: menuItem['menu_id'],
          name: (menuItem['display_name'] && menuItem['display_name'].length > 0) ? menuItem['display_name'] : menuItem['menu_name'],
          url: menuItem['menu_link'],
          type: menuItem['link_type'],
          children: [],
          content: menuItem['menu_desc']
        };
        if(menuItem['link_type'] == 1) {
          parent['attributes'] = { target: '_blank', rel: 'noopener' };
        }
        
        let newParent = this.getChildMenu(parent, parseInt(menuItem['menu_level']));
        if(!newParent['children'] || newParent['children'].length === 0) delete newParent['children'];
        parentMenu['children'].push(newParent);
      }
    }
    
    return parentMenu;
  };
  async getHeaderMenu(REDIRECT_URL_MOD, usingLink = false) {
    let headerMenu:any = null;
    
    for (const mKey in this.allmenu) {
      let menuItem = this.allmenu[mKey];
      
      if(usingLink) {
        if(menuItem['menu_link'] == REDIRECT_URL_MOD) {
          if(menuItem['parent_menu'] == 0) {
            headerMenu = menuItem;
            break;
          } else if(menuItem['parent_menu'] && menuItem['parent_menu'] != 0) {
            let parentMenu = await this.allmenu.filter(menu => {
              return menuItem['parent_menu'] == menu['menu_id'];
            });
            this.getHeaderMenu(parentMenu[0]['menu_id']);
          }
        }
      } else {
        if(menuItem['menu_id'] == REDIRECT_URL_MOD) {
          if(menuItem['parent_menu'] == 0) {
            headerMenu = menuItem;
            break;
          } else if(menuItem['parent_menu'] && menuItem['parent_menu'] != 0) {
            let parentMenu = await this.allmenu.filter(menu => {
              return menuItem['parent_menu'] == menu['menu_id'];
            });
            this.getHeaderMenu(parentMenu[0]['menu_id']);
          }
        }
      }
    }
    
    return headerMenu;
  }

  // Register FCM Token with UserId
  async registerFcmToken(fcmToken) {
    let userDetails = await this.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);
    
    return this.http.post(this.apiLink+'register_token', {fcmToken: fcmToken}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      // Set fcm token in localStorage
      localStorage.setItem('fcmToken', fcmToken);
      // localStorage.setItem('fcmMessaging', JSON.stringify(fcmDetails.messaging));
      return true;
    }).catch(err => {
      console.error(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }
  // Unregister FCM Token
  async unRegisterFcmToken(fcmToken) {
    let userDetails = await this.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);
    
    return this.http.post(this.apiLink+'user_logout', {fcmToken: fcmToken}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      console.log(this.response);
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return true;
    }).catch(err => {
      console.error(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }

  //login
  async login(loginData: loginData, loginTyoe: string) {
    return this.http.post(this.apiLink+loginTyoe, loginData).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return this.setUserDetails(this.response).then(() => {
        // this.trackAuthStatus();
        return true;
      });
    }).catch(err => {
      console.error(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }

  // Forgot Password
  async forgotPassword(email) {
    return this.http.post(this.apiLink+'forgot_password', {email: email}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      }
      return this.response.data;
    });
  }
  
}
