/**
    * @description      :
    * @author           : verdentum
    * @group            :
    * @created          : 02/08/2021 - 12:50:15
    *
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 02/08/2021
    * - Author          : verdentum
    * - Modification    :
**/
import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  // {
  //   title: true,
  //   name: 'Dropdown Components'
  // },
  {
    name: 'Dashboard',
    url: '/vadmin/dashboard',
    children: [
      {
        name: 'Setup',
        url: '/vadmin/dashboard/setup'
      },
      {
        name: 'View',
        url: '/vadmin/dashboard/view'
      },
    ]
    // icon: 'icon-speedometer',
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // }
  },
  {
    name: 'Setup',
    url: '/vadmin/setup',
    children: [
      {
        name: 'Support Account',
        url: '/vadmin/setup/admin'
      },
      {
        name: 'Client Registration',
        url: '/vadmin/setup/client'
      },
      {
        name: 'Org Registration',
        url: '/vadmin/setup/organization'
      },
    ]
  },
  {
    name: 'Global Data',
    url: '/vadmin/global',
    children: [
      {
        name: 'SDGs',
        url: '/vadmin/global/sdg'
      },
      {
        name: 'Sector',
        url: '/vadmin/global/sector'
      },
      {
        name: 'Category',
        url: '/vadmin/global/category'
      },
      {
        name: 'Module',
        url: '/vadmin/global/module'
      }
      // {
      //   name: 'Roles',
      //   url: '/vadmin/global/role'
      // }
    ]
  },
  {
    name: 'Global Template',
    url: '/vadmin/globaltemplate',
    children: [
      {
        name: 'Project',
        url: '/vadmin/globaltemplate/project/manage'
      },
      {
        name: 'Task',
        url: '/vadmin/globaltemplate/task/manage'
      },
      {
        name: 'Activity',
        url: '/vadmin/globaltemplate/activity/manage'
      },
      // {
      //   name: 'Create User',
      //   url: '#'
      // },
      // {
      //   name: 'Beneficiary',
      //   url: '#'
      // }
    ]
  },
  {
    name: 'Organization',
    url: '/vadmin/organization',
    children: [
      {
        name: 'SDGs',
        url: '/vadmin/organization/sdg'
      },
      {
        name: 'Sector',
        url: '/vadmin/organization/sector'
      },
      {
        name: 'Category',
        url: '/vadmin/organization/category'
      },
      {
        name: 'Module',
        url: '/vadmin/organization/module'
      },
      // {
      //   name: 'Locations',
      //   url: '/vadmin/organization/location'
      // },
      {
        name: 'Programs',
        url: '/vadmin/organization/program'
      },
      {
        name: 'Projects',
        url: '/vadmin/organization/project',
        children: [
          {
            name: 'Create',
            url: '/vadmin/organization/project/create'
          },
          {
            name: 'Manage',
            url: '/vadmin/organization/project/manage'
          },
          {
            name: 'Map',
            url: '/vadmin/organization/project/map'
          },
          {
            name: 'Map Location',
            url: '/vadmin/organization/project/map-location'
          },
        ]
      },
      // {
      //   name: 'Activity',
      //   url: '/vadmin/organization/activity'
      // },
      {
        name: 'Activities',
        url: '/vadmin/organization/activity',
        children: [
          {
            name: 'Create',
            url: '/vadmin/organization/activity/create'
          },
          {
            name: 'Manage',
            url: '/vadmin/organization/activity/manage'
          },
          {
            name: 'Upload',
            url: '/vadmin/organization/activity/upload'
          },
        ]
      },
      {
        name: 'Survey',
        url: '/vadmin/organization/survey'
      },
      {
        name: 'Task Management',
        url: '/vadmin/organization/task',
        children: [
          {
            name: 'Milestones',
            url: '/vadmin/organization/task/milestone/manage'
          },
          {
            name: 'Lists',
            url: '/vadmin/organization/task/tasklist/manage'
          },
          {
            name: 'Tasks',
            url: '/vadmin/organization/task/manage'
          },
          {
            name: 'Gantt Chart',
            url: '/vadmin/organization/task/ganttchart'
          }
        ]
      },
      {
        name: 'Beneficiary',
        url: '/vadmin/organization/beneficiary',
        children: [
          {
            name: 'Group',
            url: '/vadmin/organization/beneficiary/group'
          },
          {
            name: 'Create',
            url: '/vadmin/organization/beneficiary/create'
          },
          {
            name: 'Manage',
            url: '/vadmin/organization/beneficiary/manage'
          },
          // {
          //   name: 'Map',
          //   url: '/vadmin/organization/beneficiary/map'
          // }
        ]
      },
      {
        name: 'Templates',
        url: '/vadmin/organization/template',
        children: [
          {
            name: 'Project Template',
            url: '/vadmin/organization/template/project',
            children: [
              {
                name: 'Create',
                url: '/vadmin/organization/template/project/create'
              },
              {
                name: 'Manage',
                url: '/vadmin/organization/template/project/manage'
              }
            ]
          },
          {
            name: 'Activity Template',
            url: '/vadmin/organization/template/activity',
            children: [
              {
                name: 'Create',
                url: '/vadmin/organization/template/activity/create'
              },
              {
                name: 'Manage',
                url: '/vadmin/organization/template/activity/manage'
              }
            ]
          },
          {
            name: 'Task Template',
            url: '/vadmin/organization/template/task',
            children: [
              {
                name: 'Create',
                url: '/vadmin/organization/template/task/create'
              },
              {
                name: 'Manage',
                url: '/vadmin/organization/template/task/manage'
              }
            ]
          }
        ]
      },
      {
        name: 'Donor Management',
        url: '/vadmin/organization/donor_management',
        children: [
          {
            name: 'Grants Management',
            url: '/vadmin/organization/donor_management/grants_management',
          },
          {
            name: 'Donor Registration',
            url: '/vadmin/organization/donor_management/donor_registration',
          },
          {
            name: 'Expense Reporting',
            url: '/vadmin/organization/donor_management/expense_reporting',
          },
        ]
      },
      {
        name: 'Employee Management',
        url: '/vadmin/organization/employee',
        children: [
          {
            name: 'Task Report',
            url: '/vadmin/organization/employee/task-report',
          },
          {
            name: 'Employee Report',
            url: '/vadmin/organization/employee/employee-report',
          }
        ]
      },
      {
        name: 'Ticket Management',
        url: '/vadmin/organization/ticket_system'
      },
    ]
  },
  {
    name: 'Partner',
    url: '/vadmin/partner',
    children: [
      {
        name: 'Type',
        url: '/vadmin/partner/type'
      },
      {
        name: 'Details',
        url: '/vadmin/partner/details'
      },
      {
        name: 'Invite',
        url: '/vadmin/partner/invite'
      },
      // {
      //   name: 'Map',
      //   url: '/vadmin/partner/map'
      // },
    ]
  },
  {
    name: 'User Management',
    url: '/vadmin/usermgmt',
    children: [
      {
        name: 'Create',
        url: '/vadmin/usermgmt/create'
      },
      {
        name: 'Manage',
        url: '/vadmin/usermgmt/manage'
      },
      {
        name: 'Map',
        url: '/vadmin/usermgmt/map'
      },
      {
        name: 'Map Location',
        url: '/vadmin/usermgmt/map-location'
      },
    ]
  },
  {
    name: 'Worldview',
    url: '/vadmin/worldview',
    children: [
      {
        name: 'Dashboard',
        url: '/vadmin/worldview/dashboard'
      },
      // {
      //   name: 'Personnel',
      //   url: '/vadmin/worldview/personnel'
      // },
      // {
      //   name: 'Data Verification',
      //   url: '/vadmin/worldview/data_verify'
      // },
      // {
      //   name: 'Actual VS Target',
      //   url: '/vadmin/worldview/actual_vs_target'
      // }
    ]
  },
  {
    name: 'Data Management',
    url: '/vadmin/datamanagement'
  },
  {
    name: 'Statistics Analysis',
    url: '/vadmin/statistics_analysis'
  },
  {
    name: 'PIP',
    url: '/vadmin/pip',
    children: [
      {
        name: 'Create',
        url: '/vadmin/pip/create'
      },
      {
        name: 'Manage',
        url: '/vadmin/pip/manage'
      }
    ]
  },
  {
    name: 'CRM',
    url: '/vadmin/crm',
    children: [
      {
        name: 'Customer',
        url: '/vadmin/crm/customer',
        children: [
          {
            name: 'Create',
            url: '/vadmin/crm/customer/create'
          },
          {
            name: 'Follow Ups',
            url: '/vadmin/crm/customer/followup'
          },
          {
            name: 'View Leads',
            url: '/vadmin/crm/customer/lead/manage'
          },
          {
            name: 'View Clients',
            url: '/vadmin/crm/customer/client/manage'
          }
        ]
      },
      {
        name: 'NDA',
        url: '/vadmin/crm/nda'
      },
      {
        name: 'Proposal',
        url: '/vadmin/crm/proposal'
      },
      {
        name: 'Presentation',
        url: '/vadmin/crm/presentation'
      }
    ]
  },
  {
    name: 'Inventory Management',
    url: '/vadmin/inventory_system',
    children: [
      {
        name: 'Product',
        url: '/vadmin/inventory_system/product',
        children: [
          {
            name: 'Create',
            url: '/vadmin/inventory_system/product/create'
          },
          {
            name: 'Manage',
            url: '/vadmin/inventory_system/product/manage'
          },
        ]
      },
      {
        name: 'Category',
        url: '/vadmin/inventory_system/category',
        children: [
          {
            name: 'Create',
            url: '/vadmin/inventory_system/category/create'
          },
          {
            name: 'Manage',
            url: '/vadmin/inventory_system/category/manage'
          },
        ]
      },
      /* {
        name: 'Map',
        url: '/vadmin/inventory_system/map'
      },
      {
        name: 'Item',
        url: '/vadmin/inventory_system/item',
        children: [
          {
            name: 'Create',
            url: '/vadmin/inventory_system/item/create'
          },
          {
            name: 'Manage',
            url: '/vadmin/inventory_system/item/manage'
          },
        ]
      }, */
      /* {
        name: 'SKU',
        url: '/vadmin/inventory_system/sku'
      }, */
      {
        name: 'SKU',
        url: '/vadmin/inventory_system/sku',
        children: [
          {
            name: 'Create',
            url: '/vadmin/inventory_system/sku/create'
          },
          {
            name: 'Manage',
            url: '/vadmin/inventory_system/sku/manage'
          },
        ]
      },
      {
        name: 'SKU Batch',
        url: '/vadmin/inventory_system/sku_batch',
        children: [
          {
            name: 'Create',
            url: '/vadmin/inventory_system/sku_batch/create'
          },
          {
            name: 'Manage',
            url: '/vadmin/inventory_system/sku_batch/manage'
          },
        ]
      },
    ]
  }


  // {
  //   name: 'Menu',
  //   url: '/vadmin/menu',
  //   children: [
  //     {
  //       name: 'Global Menu',
  //       url: '/vadmin/menu/global-menu'
  //     },
  //     {
  //       name: 'Organization Menu',
  //       url: '/vadmin/menu/organization-menu'
  //     }
  //   ]
  // },
  // {
  //   name: 'User Management',
  //   url: '/vadmin/usermgmt',
  //   children: [
  //     {
  //       name: 'Create',
  //       url: '/vadmin/usermgmt/create'
  //     },
  //     {
  //       name: 'Manage',
  //       url: '/vadmin/usermgmt/manage'
  //     }
  //   ]
  // },
  // {
  //   name: 'Sector',
  //   url: '/vadmin/sector',
  //   // icon: 'icon-speedometer',
  //   // badge: {
  //   //   variant: 'info',
  //   //   text: 'NEW'
  //   // }
  // },
  // // {
  // //   name: 'Category',
  // //   url: '/vadmin/category',
  // //   // icon: 'icon-speedometer',
  // //   // badge: {
  // //   //   variant: 'info',
  // //   //   text: 'NEW'
  // //   // }
  // // },
  // {
  //   name: 'Project',
  //   url: '/vadmin/program',
  //   children: [
  //     {
  //       name: 'Create',
  //       url: '/vadmin/program/basicinformation'
  //     }
  //     // {
  //     //   name: 'Create',
  //     //   url: '/vadmin/program/create'
  //     // },
  //     // {
  //     //   name: 'LogFrame-Impact',
  //     //   url: '/vadmin/program/logframeimpact'
  //     // }
  //   ]
  // },
  // {
  //   name: 'Survey',
  //   url: '/vadmin/survey',
  //   children: [
  //     {
  //       name: 'Create',
  //       url: '/vadmin/survey/create'
  //     },
  //     {
  //       name: 'Manage',
  //       url: '/vadmin/survey/manage'
  //     }
  //   ]
  // },
  // {
  //   name: 'Task Management',
  //   url: '/vadmin/task',
  //   children: [
  //     {
  //       name: 'Task',
  //       url: '/vadmin/task',
  //     },
  //     {
  //       name: 'Milestones',
  //       url: '/vadmin/task/milestones',
  //     }
  //   ]
  // },
  // {
  //   name: 'Roles',
  //   url: '/vadmin/roles'
  // },
  // {
  //   name: 'Profiles',
  //   url: '/vadmin/profiles'
  // },
  // {
  //   name: 'Client Users',
  //   url: '/vadmin/client-user'
  // },
];