import { Component, EventEmitter, OnInit, ElementRef, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormioRefreshValue } from 'angular-formio/core';

import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '../../../../environments/environment';

import * as moment from 'moment';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {

  response:any;
  apiLink = apiUrl+'/api/';

  inviteForm:Object;
  surveyDetails:any = {
    id: 0,
    title: '',
    subject: '',
    location: 0,
    end_date: '',
    start_date: '',
    form_fields: '',
    beneficiary: 0,
    email_surveyor: 0
  };
  surveyWithinDateRange = {
    start: true,
    end: true
  };
  submittedData:any = null;

  langOptions:any = null;
  public refreshForm: EventEmitter<FormioRefreshValue> = new EventEmitter();

  // States
  loading = true;
  showFormio = true;

  location = {
    lat: null,
    lng: null
  };
  locationErr = null;
  loadingLocation = false;

  momentView = moment;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      console.log('params', params);
      let invite = atob(params.inviteId);

      let inviteDetails:any = JSON.parse(invite);
      if(inviteDetails.public_id == '1696160998-41355-XLG0BP') {
        this.submittedData = {
          "name_of_the_bank_banks":"",
          "select_any_of_the_following":[{"tools":"IBAT","data_procured":"","how_do_you_use_this_data":"","how_well_do_you_feel_you_understand_the_methods":""},{"tools":"ENCORE","data_procured":"","how_do_you_use_this_data":"","how_well_do_you_understand_this_method":""},{"tools":"WWF risk filters","data_procured":"","how_do_you_use_this_data":"","how_well_do_you_understand_this_method":""},{"tools":"Corporate Biodiversity Footprint","data_procured":"","how_do_you_use_this_data":"","how_well_do_you_understand_this_method":""},{"tools":"Global Biodiversity Score (GBS)","data_procured":"","how_do_you_use_this_data":"","how_well_do_you_understand_this_method":""},{"tools":"Forest 500","data_procured":"","how_do_you_use_this_data":"","how_well_do_you_understand_this_method":""},{"tools":"SPOTT","data_procured":"","how_do_you_use_this_data":"","how_well_do_you_understand_this_method":""},{"tools":"Trase finance","data_procured":"","how_do_you_use_this_data":"","how_well_do_you_understand_this_method":""},{"tools":"MSCI products","data_procured":"","how_do_you_use_this_data":"","how_well_do_you_understand_this_method":""}],
          "what_are_your_greatest_difficulties_around_biodiversity_measurement_and_reporting_banks":"","submit":true
        };
      } else this.submittedData = null;
      
      this.getInviteDetails(invite);
    });
  }

  async getInviteDetails(invite) {
    this.http.post(this.apiLink+'invite/get_invite', JSON.parse(invite)).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        this.loading = false;
        return false;
      }

      this.surveyDetails = this.response.survey;
      
      this.surveyDetails['components'] = JSON.parse(this.surveyDetails['form_fields']);
      this.surveyWithinDateRange = {
        start: true,
        end: true
      };
      if(this.surveyDetails['start_date'] && this.surveyDetails['start_date'].length > 0) {
        if(moment().isAfter(moment(this.surveyDetails['start_date']))) {
          this.surveyWithinDateRange.start = true;
        } else {
          this.surveyWithinDateRange.start = false;
        }
      }
      if(this.surveyDetails['end_date'] && this.surveyDetails['end_date'].length > 0) {
        if(moment().isBefore(moment(this.surveyDetails['end_date']))) {
          this.surveyWithinDateRange.end = true;
        } else {
          this.surveyWithinDateRange.end = false;
        }
      }
      
      this.inviteForm = {
        components: this.surveyDetails['components']
      };
      if(this.surveyDetails['display_type'] && this.surveyDetails['display_type'].length > 0) {
        this.inviteForm['display'] = this.surveyDetails['display_type'];
      }
      if(this.surveyDetails['language_option'] && this.surveyDetails['language_option'].length > 0) {
        this.langOptions =  JSON.parse(this.surveyDetails['language_option']);
      }
      this.refreshForm.emit({
        property: 'form',
        value: this.inviteForm
      });
      
      setTimeout(() => {
        console.log('submittedData', this.submittedData);
        this.loading = false;
      }, 1000);
    }).catch(err => {
      console.log(err);
      this.loading = false;
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
    });
  }

  // Set form language
  setLanguage(language: string) {
    this.langOptions.language = language;

    this.showFormio = false; // Hide the form to force re-render
    setTimeout(() => {
      this.showFormio = true; // Show the form again with updated language
    });
  }

  getDeviceLocation() {
    this.location = {
      lat: null,
      lng: null
    };
    this.locationErr = null;
    this.loadingLocation = false;
    if (!navigator.geolocation) {
      this.locationErr = "Geolocation is not supported by this browser.";
      this.loadingLocation = false;
      return false;
    }

    this.loadingLocation = true;
    navigator.geolocation.getCurrentPosition(position => {
      this.location = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      this.loadingLocation = false;
    });
  }

  onSubmit(submission: any) {
    console.log(submission); // This will print out the full submission from Form.io API.

    let data = {
      form_id: this.surveyDetails.id,
      locDetails: this.location,
      form_data: JSON.stringify(submission.data)
    }
    this.http.post(this.apiLink+'invite/collect_survey', data).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
      } else {
        // this.toastr.success(this.response.msg, 'Success!', {
        //   timeOut: 5000,
        // });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 4000);
        this.router.navigate(['invite/success']);
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
    });
  }

}
