import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { getNavData } from './_nav';

import { AuthService } from '../services/auth.service';
import { SocketService } from './services/socket.service';

import { apiUrl } from '../../environments/environment';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ThemeService } from './services/theme.service';
import { FcmService } from '../services/fcm.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  @ViewChild('navContainer', { static: false }) navContainer!: ElementRef;

  scrollDirection: 'left' | 'right' = 'right'; // default direction if needed

  public currentYear = new Date();
  public sidebarMinimized = false;
  public navItems:any[] = [];
  public headerItems:any[] = [];

  name = '';
  menu: any;
  sideMenu = [];
  currentHead = {};
  userDetails:any;
  apiLink = apiUrl;
  headerLogo = 'assets/img/brand/verdentumlogo_green.png';
  footerLogo = '';
  orgDetails = {
    pm_logo: null
  };

  response:any;
  pushMessage: any;

  themes = [
    { name: 'Default Theme', color: '#2e2c34', id: 'default' },
    { name: 'Midnight Blue Theme', color: '#0f042b', id: 'midnight-blue' },
    { name: 'Black Coffee Theme', color: '#1c0700', id: 'black-coffee' },
    { name: 'Black Red Theme', color: '#190000', id: 'black-red' },
    { name: 'Midnight Green Theme', color: '#001721', id: 'midnight-green' },
    { name: 'Dark Maroon Theme', color: '#26000e', id: 'dark-maroon' },
    { name: 'Deep Navy Theme', color: '#00152c', id: 'deep-navy' },
    { name: 'Dark Forest Green Theme', color: '#132001', id: 'dark-forest-green' },
    { name: 'Deep Maroon Theme', color: '#270007', id: 'deep-maroon' },
    { name: 'Deep Brownish Red Theme', color: '#200800', id: 'deep-brownish-red' },
    { name: 'Dark Coffee Theme', color: '#1f1500', id: 'dark-coffee' },
    { name: 'Dark Olive Theme', color: '#121500', id: 'dark-olive' },
    { name: 'Dark Cerulean Theme', color: '#002334', id: 'dark-cerulean' },
  ];
  currentTheme = '';
  zoomFactor = 1;

  constructor(
    private router: Router,
    private http: HttpClient,
    private renderer: Renderer2,
    private toastr: ToastrService,
    public authService: AuthService,
    public socketSercie: SocketService,
    public themeService: ThemeService,
    private fcmService: FcmService,
    private readonly tourService: TourService,
  ) {
    let user:any = localStorage.getItem('USER_DATA');
    this.menu = JSON.parse(user).menu;

    const navData = getNavData();
    this.navItems = navData.navItems;
    this.headerItems = navData.headerItems;

    if(this.headerItems.length === 0) {
      this.getHeaderMenu();
    }

    this.orgDetails = JSON.parse(user).organization;
    if(this.orgDetails.pm_logo && this.orgDetails.pm_logo != 'default_user.png') {
      this.headerLogo = this.apiLink + '/uploads/' + this.orgDetails.pm_logo;
      this.footerLogo = 'assets/img/brand/verdentumlogo_green.png';
    } else {
      this.footerLogo = '';
      this.headerLogo = 'assets/img/brand/verdentumlogo_green.png';
    }

    this.authService.urlChangeObservable.subscribe((res:any) => {
      if(res) {
        // Clear left side
        if(res == 'clearLeftSide') {
          localStorage.removeItem('currentSides');
          localStorage.removeItem('selectedSide');
          this.navItems = [];
        }

        // Clear current head menu
        if(res == 'currentHead') {
          localStorage.removeItem('currentHead');
          this.currentHead = {};
        }
      }
    });

    this.fcmService.receiveMessage();
    this.fcmService.currentMessage.subscribe((message) => {
      this.pushMessage = message;
    });
  }

  async ngOnInit() {
    await this.getUserDetails();

    // Check if header menu is set in localStorage
    let currentHead = localStorage.getItem('currentHead');
    if(currentHead && currentHead.length > 0) {
      this.currentHead = JSON.parse(currentHead);
    }

    // Check if side menu is set in localStorage
    let currentSides = localStorage.getItem('currentSides');
    if(currentSides && currentSides.length > 0) {
      this.navItems = JSON.parse(currentSides);
    }

    if(localStorage.getItem('selectedSide')) {
      let link = localStorage.getItem('selectedSide');
      // if(link.length > 0) this.router.navigate([link]);
    }

    // Check if first time login of user
    // Then start tour
    if(this.userDetails.user.profile_filled != 1) {
      this.setupTour();
    }
    
    if(this.authService.isAuthenticated()) this.chnekUsertheme();
  }
  ngAfterViewInit() {
    const container = this.navContainer.nativeElement;
    const liElements = Array.from(container.querySelectorAll('li')) as HTMLElement[];
  
    if (liElements.length === 0) {
      return;
    }
  
    // Find the <li> with the 'active' class
    const activeLi = liElements.find((li: HTMLElement) => li.classList.contains('active'));
  
    if (activeLi) {
      activeLi.classList.add('inView');
    } else {
      // Fallback to the first <li>
      liElements[0].classList.add('inView');
    }
  }

  async setupTour() {
    let tourItems:any[] = [];
    for(const itm of this.headerItems) {
      let item:any = itm;
      if(item.url != 'user/post') {
        tourItems.push({
          anchorId: item.menu_id.toString(),
          content: item.content,
          title: item.name,
          route: 'user/'+item.url
        });
      } else {
        tourItems.push({
          anchorId: item.menu_id.toString(),
          content: 'Get real-time updates on what is happening on the ground for your projects. Scroll through the data and image uploads by the team as if you are using a social media platform.',
          title: item.name,
          route: item.url
        });
      }
    }
    this.tourService.initialize(tourItems);
    this.tourService.start();
    this.tourService.end$.subscribe(() => {
      this.userDetails.user.profile_filled = 1;
      this.authService.setUserDetails({
        type: this.userDetails.type,
        token: this.userDetails.token,
        user: [this.userDetails.user]
      });
      this.updatePrivacy();
    });
  }

  getHeaderMenu() {
    let navItemsServer:any[] = [], headerItems:any[] = [];
    
    let getChildMenu = (parentMenu:any, menu_level:number) => {
      for (const mKey in this.menu) {
        let menuItem = this.menu[mKey];
        
        if(menuItem['menu_level'] == (menu_level+1)
        && menuItem['parent_menu'] == parentMenu['menu_id']) {
          let parent = {
            menu_level: menuItem['menu_level'],
            menu_id: menuItem['menu_id'],
            name: (menuItem['display_name'] && menuItem['display_name'].length > 0) ? menuItem['display_name'] : menuItem['menu_name'],
            url: menuItem['menu_link'],
            type: menuItem['link_type'],
            children: [],
            content: menuItem['menu_desc']
          };
          if(menuItem['link_type'] == 1) {
            parent['attributes'] = { target: '_blank', rel: 'noopener' };
          }
          
          let newParent = getChildMenu(parent, parseInt(menuItem['menu_level']));
          if(newParent['children'].length === 0) delete newParent['children'];
          parentMenu['children'].push(newParent);
        }
      }
      
      return parentMenu;
    };
    for (const mKey in this.menu) {
      let menuItem = this.menu[mKey];
      if(menuItem['menu_level'] == 0) {
        let parentMenu = {
          menu_level: menuItem['menu_level'],
          menu_id: menuItem['menu_id'],
          name: (menuItem['display_name'] && menuItem['display_name'].length > 0) ? menuItem['display_name'] : menuItem['menu_name'],
          url: menuItem['menu_link'],
          type: menuItem['link_type'],
          children: [],
          content: menuItem['menu_desc']
        };
        if(menuItem['link_type'] == 1) {
          parentMenu['attributes'] = { target: '_blank', rel: 'noopener' };
        }
    
        let newParentMenu = getChildMenu(parentMenu, parseInt(menuItem['menu_level']));
        if(newParentMenu['children'].length === 0) delete newParentMenu['children'];
    
        // If first header menu and has children
        if(parseInt(mKey) === 0 && newParentMenu['children'] && newParentMenu['children'].length > 0) {
          // Push children to side nav
          navItemsServer.push(newParentMenu);
        }
    
        // Push Patent Menus in Header
        headerItems.push(newParentMenu);
      }
    }

    this.headerItems = headerItems;
  }

  async updatePrivacy() {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'/api/'+'usermgmt/update_pf', {}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
    });
  }

  async getUserDetails() {
    this.userDetails = await this.authService.getUserDetails();
    // Check if user is still logged in
    if(this.userDetails.relogin && this.userDetails.relogin == 1) {
      await this.authService.removeUserDetails();
      this.authService.trackAuthStatus();
    } else if(this.userDetails.type != 'user') {
      this.authService.trackAuthStatus();
    }

    this.name = this.userDetails.user.reg_title ? ' '+this.userDetails.user.reg_mname : '';
    this.name += this.userDetails.user.reg_title ? ' '+this.userDetails.user.reg_fname : this.userDetails.user.reg_fname;
    this.name += this.userDetails.user.reg_mname ? ' '+this.userDetails.user.reg_mname : '';
    this.name += this.userDetails.user.reg_lname ? ' '+this.userDetails.user.reg_lname : '';
  }

  getChildMenu(parentMenu:any) {
    if(parentMenu.children && parentMenu.children.length > 0) {
      this.navItems = parentMenu.children;
    } else {
      this.navItems = [];
    }

    // Set current header menu in localStorage
    this.currentHead = parentMenu;
    localStorage.setItem('currentHead', JSON.stringify(parentMenu));
    // Set current side menu in localStorage
    localStorage.setItem('currentSides', JSON.stringify(this.navItems));
    if(this.navItems.length > 0) localStorage.setItem('selectedSide', 'user/' + this.navItems[0]['url']);
    else this.authService.urlChangeObservable.next('clearLeftSide');

    // Redirect to proper page
    if(parentMenu.type != 2 && this.navItems.length > 0) this.router.navigate(['user/' + this.navItems[0]['url']]);
    else this.router.navigate(['user/' + parentMenu['url']]);

    // call observable next everytime page changes
    this.authService.urlChangeObservable.next(null);
  }
  setSelectedMenu() {
    localStorage.setItem('selectedSide', '');
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  async logout() {
    await this.authService.removeUserDetails();
    this.authService.trackAuthStatus();
  }

  navScroll(direction: 'left' | 'right') {
    const container = this.navContainer.nativeElement;
    const liElements = Array.from(container.querySelectorAll('li')) as HTMLElement[];
  
    if (liElements.length === 0) {
      return;
    }
  
    const currentInViewIndex = liElements.findIndex(li => li.classList.contains('inView'));
  
    if (currentInViewIndex === -1) {
      return;
    }
  
    let newInViewIndex = currentInViewIndex;
    if (direction === 'right') {
      newInViewIndex = Math.min(currentInViewIndex + 1, liElements.length - 1);
    } else if (direction === 'left') {
      newInViewIndex = Math.max(currentInViewIndex - 1, 0);
    }
  
    liElements[currentInViewIndex].classList.remove('inView');
    liElements[newInViewIndex].classList.add('inView');
  
    const containerRect = container.getBoundingClientRect();
    const newInViewRect = liElements[newInViewIndex].getBoundingClientRect();
  
    const offset = newInViewRect.left - containerRect.left;
    container.style.transform = `translateX(-${offset}px)`;
  }

  async finishTour() {}
  
  toogleBlackTheme() {
    if(this.currentTheme == 'black-theme') {
      this.loadStyle('default');
    } else {
      this.loadStyle('black-theme');
    }
  }
  
  ///css changer
  async loadStyle(styleName: string) {
    const head = document.getElementsByTagName('head')[0];

    const existingStyle = document.getElementById('client-theme');
    // Remove the previously added style if it exists
    if (existingStyle) {
      head.removeChild(existingStyle);
    }

    const style = document.createElement('link');
    if(styleName && styleName.length > 0) {
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = 'assets/themes/'+styleName+'.css';
    } else {
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = 'assets/themes/default.css';
    }
    head.appendChild(style);
    
    let data = {
      user_id: this.userDetails.user.id,
      theme_name: styleName
    };
    let chnageTheme = await this.themeService.addTheme(data);
    if(chnageTheme){
      this.toastr.success('Theme Successfully Changed.', 'Success!', {
        timeOut: 3000,
      });
    }
    if(styleName == '' || styleName == 'default'){
      this.toastr.success('Theme has been reset to default.', 'Success!', {
        timeOut: 3000,
      });
      // window.location.reload();
    }
    this.currentTheme = styleName;
  }

  //apply theme
  async chnekUsertheme(){
    let data = {
      user_id: this.userDetails.user.id,
    };
    let getTheme:any;
    try {
      getTheme = await this.themeService.getuserTheme(data);
      const head = document.getElementsByTagName('head')[0];
      
      const existingStyle = document.getElementById('client-theme');
      // Remove the previously added style if it exists
      if (existingStyle) {
        head.removeChild(existingStyle);
      }
      
      const style = document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      
      let themeId = '';
      if( getTheme && getTheme.length > 0 && getTheme[0].theme_name && getTheme[0].theme_name.length > 0) {
        style.href = 'assets/themes/'+getTheme[0].theme_name+'.css';
        themeId = getTheme[0].theme_name;
      } else {
        style.href = 'assets/themes/default.css';
        themeId = 'default';
      }
      head.appendChild(style);
      this.currentTheme = themeId;
    } catch(err) {
      console.error(err)
    }
  }

  // Zoom In: Increases font size
  zoomIn() {
    if (this.zoomFactor < 1.5) {
      this.zoomFactor += 0.1;
      this.applyZoom();
    }
  }
  // Zoom Out: Decreases font size
  zoomOut() {
    if (this.zoomFactor > 0.5) {
      this.zoomFactor -= 0.1;
      this.applyZoom();
    }
  }
  // Reset Font Size: Returns to the default font size
  resetFontSize() {
    this.zoomFactor = 1;
    this.applyZoom();
  }
  // Apply the current zoom factor to the document's font size
  private applyZoom() {
    this.renderer.setStyle(document.documentElement, 'font-size', `${this.zoomFactor * 100}%`);
  }
}
