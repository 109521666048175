/**
    * @description      :
    * @author           : verdentum
    * @group            :
    * @created          : 02/08/2021 - 12:50:15
    *
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 02/08/2021
    * - Author          : verdentum
    * - Modification    :
**/
import { INavData } from '@coreui/angular';

export function getNavData(): { headerItems: any[]; navItems: any[] } {

  let navItems: INavData[] = [];

  let user:any = localStorage.getItem('USER_DATA');
  let menu = JSON.parse(user);
  let headerItems = [
    // { menu_level: 0, menu_id: 0, name: 'Post', url: '/user/post',
    //   children: [
    //     { name: 'Global news', url: ' ' },
    //     { name: 'News by region', url: ' ' },
    //     { name: 'Million Acts of Kindness', url: 'http://3.109.148.216/aok/',attributes: { target: '_blank', rel: 'noopener' }},
    //     { name: 'News by SDG', url: ' ' },
    //   ]
    // }
  ],
  navItemsServer = [
    // { name: 'Dashboard', url: '/user/dashboard' }
  ];
  menu = menu?.menu ?  menu?.menu : [];

  // Get all child menus
  let getChildMenu = (parentMenu:any, menu_level:number) => {
    for (const mKey in menu) {
      let menuItem = menu[mKey];
      
      if(menuItem['menu_level'] == (menu_level+1)
      && menuItem['parent_menu'] == parentMenu['menu_id']) {
        let parent = {
          menu_level: menuItem['menu_level'],
          menu_id: menuItem['menu_id'],
          name: (menuItem['display_name'] && menuItem['display_name'].length > 0) ? menuItem['display_name'] : menuItem['menu_name'],
          url: menuItem['menu_link'],
          type: menuItem['link_type'],
          children: [],
          content: menuItem['menu_desc']
        };
        if(menuItem['link_type'] == 1) {
          parent['attributes'] = { target: '_blank', rel: 'noopener' };
        }
        
        let newParent = getChildMenu(parent, parseInt(menuItem['menu_level']));
        if(newParent['children'].length === 0) delete newParent['children'];
        parentMenu['children'].push(newParent);
      }
    }
    
    return parentMenu;
  };
  for (const mKey in menu) {
    let menuItem = menu[mKey];
    if(menuItem['menu_level'] == 0) {
      let parentMenu = {
        menu_level: menuItem['menu_level'],
        menu_id: menuItem['menu_id'],
        name: (menuItem['display_name'] && menuItem['display_name'].length > 0) ? menuItem['display_name'] : menuItem['menu_name'],
        url: menuItem['menu_link'],
        type: menuItem['link_type'],
        children: [],
        content: menuItem['menu_desc']
      };
      if(menuItem['link_type'] == 1) {
        parentMenu['attributes'] = { target: '_blank', rel: 'noopener' };
      }

      let newParentMenu = getChildMenu(parentMenu, parseInt(menuItem['menu_level']));
      if(newParentMenu['children'].length === 0) delete newParentMenu['children'];

      // If first header menu and has children
      if(parseInt(mKey) === 0 && newParentMenu['children'] && newParentMenu['children'].length > 0) {
        // Push children to side nav
        navItemsServer.push(newParentMenu);
      }

      // Push Patent Menus in Header
      headerItems.push(newParentMenu);
    }
  }

  return {
    headerItems: headerItems,
    navItems : navItemsServer
  };
}
