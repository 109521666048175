import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../../services/auth.service';
import { LookupService } from '../../services/lookup.service';

import { apiUrl } from '../../../environments/environment'

@Component({
  selector: 'app-af-benef-profile',
  templateUrl: './af-benef-profile.component.html',
  styleUrls: ['./af-benef-profile.component.scss']
})
export class AfBenefProfileComponent implements OnInit {

  apiLink = apiUrl;
  phone_no:any = null;
  member_id:any = null;
  benefDetails:any = {
    id: null
  };
  agriProfile:any = null;
  groupSavings:any[] = [];

  total = {
    bank: 0,
    loan: 0,
    saving: 0,
    received: 0,
    interest: 0,
  }

  landDetails:boolean = false;
  groupDetails:boolean = false;
  familyDetails:boolean = false;
  incomeDetails:boolean = false;

  // States
  loading = false;
  searching = false;
  loadingSavingsData = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastService: ToastrService,
    private lookupService: LookupService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.getBenefDetails(params.dataid);
    });
  }

  async getBenefDetails(dataId) {
    this.benefDetails = {
      id: null
    };
    if(!dataId || dataId.length === 0) {
      return
    }
    
    this.loading = true;
    this.loadingSavingsData = true;
    this.benefDetails = {
      id: null
    };
    
    this.http.get(this.apiLink+'/api/lookup-af/get_beneficiary?data_id='+dataId).toPromise().then(async (response: any) => {
      this.loading = false;
      if(response.status == 0) {
        this.toastService.error(response.msg, 'Error!', {
          timeOut: 5000,
        });
        return;
      }
      
      this.benefDetails = response.data[0];
      await this.getSavingsDetails();
      await this.getAgriProfile();
      this.loadingSavingsData = false;
    });
  }
  async getSavingsDetails() {
    this.groupSavings = [];

    this.http.get(this.apiLink+`/api/lookup-af/get_savings?group_id=${this.benefDetails.group_id}&org_code=${this.benefDetails.org_code}`).toPromise().then((response: any) => {
      if(response.status == 0) {
        this.toastService.error(response.msg, 'Error!', {
          timeOut: 5000,
        });
        return;
      }
      
      this.groupSavings = response.data;
      for (const gs of this.groupSavings) {
        for (const ms of gs.memberSavings) {
          this.total.saving = this.total.saving + parseFloat(ms.savingsPaid);
          this.total.loan = this.total.loan + parseFloat(ms.loan_amount_taken_this_month_in_rs_af);
          this.total.received = this.total.received + parseFloat(ms.loanPaid);
          this.total.interest = this.total.interest + parseFloat(ms.interestPaid);
          this.total.bank = (this.total.saving + this.total.received + this.total.interest) - this.total.loan;
        }
      }
    });
  }
  async getAgriProfile() {
    this.agriProfile = null;
    
    this.http.get(this.apiLink+`/api/lookup-af/get_agri_profile?data_id=${this.benefDetails.data_id}&org_code=${this.benefDetails.org_code}`).toPromise().then((response: any) => {
      if(response.status == 0) {
        this.toastService.error(response.msg, 'Error!', {
          timeOut: 5000,
        });
        return;
      }
      
      if(response.data) this.agriProfile = response.data;
    });
  }

  async searchBeneficiary() {
    if((!this.phone_no || this.phone_no.trim().length === 0) && (!this.member_id || this.member_id.trim().length === 0)) {
      this.toastService.error('Please provide atleast the Phone Number or the Member ID to search.', 'Error!', {
        timeOut: 5000,
      });
      return;
    }

    this.searching = true;
    let url = this.apiLink+`/api/lookup-af/search_beneficiary`;
    if(this.phone_no && this.phone_no.length > 0) {
      url = url + `?phone_no=${this.phone_no}`;
    }
    if(this.member_id && this.member_id.length > 0) {
      url = url + `?member_id=${this.member_id}`;
    }
    this.http.get(url).toPromise().then((response: any) => {
      this.searching = false;
      if(response.status == 0) {
        this.toastService.error(response.msg, 'Error!', {
          timeOut: 5000,
        });
        return;
      }
      
      if(response.data.data_id && response.data.data_id.length > 0) {
        this.router.navigate(['/beneficiary-profile/af'], {
          queryParams: { dataid: response.data.data_id }
        });
      } else {
        this.toastService.error('No beneficiary found according to the search. Please provide some different query.', 'Error!', {
          timeOut: 5000,
        });
        return;
      }
    });
  }

  toggleLandDetails() {
    this.landDetails = !this.landDetails;
  }
  toggleGroupDetails() {
    this.groupDetails = !this.groupDetails;
  }
  toggleFamilyDetails() {
    this.familyDetails = !this.familyDetails;
  }
  toggleIncomeDetails() {
    this.incomeDetails = !this.incomeDetails;
  }

}
