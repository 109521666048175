import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WithAuthGuard } from './guards/with-auth.guard';
import { WithoutAuthGuard } from './guards/without-auth.guard';

// import { VadminModule } from './vadmin/vadmin.module';
// import { UserModule } from './user/user.module';

// Import Containers
import { P404Component } from './vadmin/error/404.component';
import { LoginComponent } from './public/login/login.component';
import { AdminLoginComponent } from './public/admin-login/admin-login.component';
import { SignupComponent } from './public/signup/signup.component';

import { InviteComponent } from './public/invite/invite.component';
import { GeneralComponent as InviteGeneralComponent } from './public/invite/general/general.component';
import { PartnerComponent as InvitePartnerComponent } from './public/invite/partner/partner.component';
import { SuccessComponent as InviteSuccessComponent } from './public/invite/success/success.component';

import { ViewdataComponent } from './public/viewdata/viewdata.component';
import { SurveyComponent as ViewSurveyComponent } from './public/viewdata/survey/survey.component';

import { IntroComponent } from './intro/intro.component';
import { VerdentumPrivacyMobileComponent } from './intro/verdentum-privacy-mobile/verdentum-privacy-mobile.component';
import { VerdentumPrivacyComponent } from './intro/verdentum-privacy/verdentum-privacy.component';
import { OrgPrivacyComponent } from './intro/org-privacy/org-privacy.component';
import { VideoComponent } from './intro/video/video.component';
import { ForgotPasswordComponent } from './public/login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './public/login/reset-password/reset-password.component';
import { PublicShareableDashboardComponent } from './public/public-shareable-dashboard/public-shareable-dashboard.component';
import { SharePostComponent } from './public/share-post/share-post.component';
import { RfpFormComponent } from './public/rfp-form/rfp-form.component';
import { PipComponent } from './public/pip/pip.component';
import { RfqComponent } from './public/rfq/rfq.component';
import { AfBenefProfileComponent } from './public/af-benef-profile/af-benef-profile.component';

export const routes: Routes = [
 
  // Default Redirect Path
  {path: '', redirectTo: 'login', pathMatch: 'full'},

  {
    path: 'login',
    canActivate: [WithoutAuthGuard],
    children: [
      // Default Redirect path
      {path: '', component: LoginComponent, pathMatch: 'full'},   
      // Forgot Password path
      {path:'forgot_password', component: ForgotPasswordComponent, data: {title: 'Login / Forgot Password'}},
      {path:'reset_password/:key', component: ResetPasswordComponent, data: {title: 'Login / Reset Password'}},
    ]
  },

  {
    path: 'signup',
    component: SignupComponent,
    data: {
      title: 'Verdentum :: SignUp'
    }, canActivate: [WithoutAuthGuard]
  },
  {
    path: 'invite',
    component: InviteComponent,
    canActivate: [WithoutAuthGuard],
    children: [
      // Invite Paths
      {path:'success', component: InviteSuccessComponent, data: {title: 'Verdentum :: Invite :: Success'}},
      {path:'general/:inviteId', component: InviteGeneralComponent, data: {title: 'Verdentum :: Invite'}},
      {path:'partner/:inviteId', component: InvitePartnerComponent, data: {title: 'Verdentum :: Invite / Partner'}}
    ]
  },
  {
    path: 'viewdata',
    component: ViewdataComponent,
    canActivate: [WithoutAuthGuard],
    children: [
      // Viewdata Paths
      {path:'survey/:dataId', component: ViewSurveyComponent, data: {title: 'Survey :: View Data'}}
    ]
  },
  // Public dashboard path
  {
    path: 'public-shareable-dashboard/:data',
    component: PublicShareableDashboardComponent
  },
  // Public Share Post path
  {
    path: 'share_post/:data',
    component: SharePostComponent
  },

  {path: 'admin', redirectTo: 'admin/login', pathMatch: 'full'},
  {
    path: 'admin/login',
    component: AdminLoginComponent,
    data: {
      title: 'Verdentum :: Admin Login'
    }, canActivate: [WithoutAuthGuard]
  },

  {
    path:'intro',
    component: IntroComponent,
    children: [
      // Default Redirect path
      {path: '', redirectTo: 'verdentum_privacy', pathMatch: 'full'},
      // Intro Paths
      {path:'verdentum_privacy_mobile_close', component: VerdentumPrivacyMobileComponent, data: {title: 'Intro / Verdentum Privacy'}},
      {path:'verdentum_privacy_mobile', component: VerdentumPrivacyMobileComponent, data: {title: 'Intro / Verdentum Privacy'}},
      {path:'verdentum_privacy', component: VerdentumPrivacyComponent, data: {title: 'Intro / Verdentum Privacy'}},
      {path:'org_privacy', component: OrgPrivacyComponent, data: {title: 'Intro / Organization Privacy'}},
      {path:'video', component: VideoComponent, data: {title: 'Intro / Video'}}
    ]
  },
  {
    path: 'rfp_form/:publicid',
    component: RfpFormComponent
  },

  {
    path: 'vadmin', canActivate: [WithAuthGuard],
    loadChildren: () => import('./vadmin/vadmin.module').then(m => m.VadminModule)
  },

  {
    path: 'user', canActivate: [WithAuthGuard],
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },

  {
    path: 'pip/:pipData',
    component: PipComponent,
    canActivate: [WithoutAuthGuard], data: {title: 'Verdentum :: PIP'}
  },

  {
    path: 'rfq/:rfqData',
    component: RfqComponent,
    canActivate: [WithoutAuthGuard], data: {title: 'Verdentum :: RFQ'}
  },

  {
    path: 'beneficiary-profile/af',
    component: AfBenefProfileComponent,
    data: {title: 'Verdentum :: Beneficiary Profile'}
  },
  
  // Wildcard Path
  // { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
